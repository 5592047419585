import React from "react";
import PageHeader from "../components/PageHeader";
import ServiceArea from "../components/ServiceArea";
import InformationArea from "../components/InformationArea";

function Hizmetlerimiz(language) {
  const das =
    language != "fr" ? (
      <ServiceArea language={language}></ServiceArea>
    ) : (
      <div className="text-center">
        <h2 style={{ marginTop: "100px", marginBottom: "100px" }}>
          Coming Soon...
        </h2>
      </div>
    );
  return (
    <section>
      {PageHeader("hizmetlerimiz", language)}
      {das}
      <InformationArea language={language}></InformationArea>
    </section>
  );
}

export default Hizmetlerimiz;
